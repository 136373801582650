<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.plan.crud.view }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
              {{ err_msg }}
            </CAlert>
            <div class="detail-div">
              <CRow>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.type }} :</span> <span>{{ plan.type.label }}</span>
                  </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.name }} :</span> <span>{{ plan.name }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.duration }} :</span> <span>{{ plan.duration.label }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.no_of }} :</span> <span>{{ plan.no_of }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.status }} :</span> <span>{{ plan.status.label }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.no_user }} :</span> <span>{{ plan.no_user }}</span>
                  </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.no_branch }} :</span> <span>{{ plan.no_branch }}</span>
                  </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.featuredetail }} :</span>
                    <span>{{ plan.featuredetail }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.plan.form.industry }} :</span> <span>{{ plan.industryTitle }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p>
                    <span class="custome-labal">{{ $lang.plan.form.can_join }} : </span>
                    <span v-if="plan.can_join === 1">Yes</span>
                    <span v-if="plan.can_join === 0">No</span>
                  </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p>
                    <span class="custome-labal">{{ $lang.plan.form.is_free }} : </span>
                    <span v-if="plan.is_free === 1">Yes</span>
                    <span v-if="plan.is_free === 0">No</span>
                  </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p>
                    <span class="custome-labal">{{ $lang.plan.form.is_auto_assign }} : </span>
                    <span v-if="plan.is_auto_assign === 1">Yes</span>
                    <span v-if="plan.is_auto_assign === 0">No</span>
                  </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p>
                    <span class="custome-labal">{{ $lang.plan.form.freshHired }} : </span>
                    <span v-if="plan.freshHired === 1">Yes</span>
                    <span v-if="plan.freshHired === 0">No</span>
                  </p>
                </CCol>
                <CCol sm="12" md="12" lg="12">
                  <p>
                    <span class="custome-labal d-block">{{ $lang.plan.form.description }} :</span>
                    <span class="d-block">{{ plan.description }}</span>
                  </p>
                </CCol>
              </CRow>
              <span v-if="inputs.length > 0">
                <h4>Plan Price</h4>
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $lang.plan.form.country }}</th>
                    <th>{{ $lang.plan.form.price }}</th>
                    <th>{{ $lang.plan.form.price_user }}</th>
                  </tr>
                  <tr v-for="input in inputs" :key="input.id">
                    <td>{{ input.countryId.label }}</td>
                    <td>{{ input.priceWithCurrency }}</td>
                    <td>{{ input.per_person_priceWithCurrency }}</td>
                  </tr>
                </table>
              </span>
              <!--              <h4>Side Menu</h4>-->
              <!--              <ul class="list-unstyled feature-list">-->
              <!--                <li v-for="(features,index) in sideMenuList" v-if="features.selected">-->
              <!--                  {{features.selected.label}}-->
              <!--                </li>-->
              <!--              </ul>-->
              <h4>Menu</h4>
              <ul class="list-unstyled feature-list">
                <li v-for="(features,index) in bottomMenuList" v-if="features.selected">
                  {{ features.selected.label }}
                  <span v-for="(sub,index) in features.permission" v-if="features.permission">
                    <br>
                    - {{ sub.label }}
                  </span>
                </li>
              </ul>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {country, plan} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import {Maxlength} from "../../store/maxlength";
import $ from "jquery";

Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "ViewPlan",
  mixins: [Mixin],
  data() {
    return {
      counter: 0,
      inputs: [],
      bottomMenuList: [],
      // sideMenuList:[],
      module: plan,
      exampleData: [],
      messageColor: "",
      submitted: false,
      SubmitBtn: "Submit",
      err_msg: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      moduleCountry: country,
      msg: "",
      typeOptions: [
        {
          'id': 'Company',
          'label': 'Company',
        },
        {
          'id': 'Individual',
          'label': 'Individual',
        },
      ],
      durationOptions: [
        {
          'id': 'Yearly',
          'label': 'Yearly',
        },
        {
          'id': 'Monthly',
          'label': 'Monthly',
        },
        {
          'id': 'Days',
          'label': 'Days',
        },
      ],
      statusOptions: [
        {
          'id': '0',
          'label': 'Inactive',
        },
        {
          'id': '1',
          'label': 'Active',
        },
      ],
      countryOptions: [],
      editId: '',
      planPricing: '',
      userplanPricing: '',
      plan: {
        type: "",
        name: "",
        description: "",
        duration: "",
        status: "",
        no_user: "",
        no_branch: "",
        can_join: 0,
        is_free: 0,
        no_of: '',
        featuredetail: "",
        industryTitle: "",
        is_auto_assign: 0,
        freshHired: 0,
      },
      maxlength: {
        name: Maxlength.plan.name,
        description: Maxlength.plan.description,
        no_user: Maxlength.plan.no_user,
        no_branch: Maxlength.plan.no_branch,
        price: Maxlength.plan.price,
        no_of: Maxlength.plan.no_of,
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    axios.get('/countries/lists').then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });
    self.editId = this.$route.params.id;
    axios.get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          const responseData = response.data.data;
          self.plan.type = {
            id: responseData.type,
            label: responseData.type,
          };
          self.plan.industryTitle = responseData.industryTitle;
          self.plan.name = responseData.name;
          self.plan.description = responseData.description;
          self.plan.duration = {
            id: responseData.durationType,
            label: responseData.durationType,
          };
          if (responseData.status === 1) {
            self.plan.status = {
              id: '1',
              label: 'Active',
            };
          } else {
            self.plan.status = {
              id: '0',
              label: 'Inactive',
            };
          }
          self.plan.no_user = responseData.noOfUsers;
          self.plan.no_branch = responseData.noOfBranch;
          self.plan.can_join = responseData.internationalGroup;
          self.plan.is_free = responseData.isFree;
          self.plan.is_auto_assign = responseData.isAutoAssign;
          self.plan.freshHired = responseData.isFreshHired;
          self.bottomMenuList = responseData.bottomMenuList;
          if (self.plan.is_free === 1) {
            $('#plan_pricing').css('display', 'none');
          } else {
            $('#plan_pricing').css('display', 'block');
          }
          self.plan.no_of = responseData.duration;
          self.inputs = responseData.planPricing;
          self.plan.featuredetail = responseData.features;
          self.feature = responseData.planFeaturesShow;
          store.commit("showLoader", false);
        })
        .catch(function (error) {
          store.commit("showLoader", false);
        });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    setAlert(value) {
      this.showAlert = value;
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
